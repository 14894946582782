import Triangles1 from "../../../../src/posts/mesh-background/triangles1.js";
import Point from "../../../../src/posts/mesh-background/point.js";
import Points from "../../../../src/posts/mesh-background/points.js";
import PointsMesh from "../../../../src/posts/mesh-background/PointsMesh.js";
import * as React from 'react';
export default {
  Triangles1,
  Point,
  Points,
  PointsMesh,
  React
};